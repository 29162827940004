/*
 * 업무구분: 고객
 * 화 면 명: MSPCM135M
 * 화면설명: 비대면동의 발송이력
 * 작 성 일: 2023.03.15
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="비대면동의 발송이력" :show-title="false" type="subpage" :topButton="true" @on-header-left-click="fn_HistoryBack" @on-scroll-bottom="fn_LoadingData" @action-search-click="fn_OpenMSPCM116P">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar" >
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="collapsingHeaderView">
          <!-- 상단고정 Tab UI -->
          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="scrollElementId" scroll-shadow>
              <div slot="nav" class="icon-wrapper" >
                  <mo-icon color="white" icon-size="36px" borderless @click="fn_HistoryBack">drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '비대면동의 발송이력' : '비대면동의 발송이력'}}</div>
              <div v-if="hasAuth" slot="action" @click="fn_OpenMSPCM116P()" ><mo-icon >search</mo-icon> </div><!-- icon-size="36px" -->
              </mo-top-bar>
          </template>
          <!-- 헤더 타이틀 영역 //-->
          <!-- collapsing 영역 -->
          <template #collapsing>
            <div style="height: 1px"></div>
          </template>
          <!-- stickey 영역 --> 
          <template #sticky>
            <mo-tab-box :default-idx="tabIndex" primary class="ns-move-tab-box" @tab-change="fn_ChangeTab">
              <!-- tab id loop -->
              <mo-tab-label :idx="1">신규고객</mo-tab-label>
              <mo-tab-label :idx="2">기존고객</mo-tab-label>
              <!-- tab id loop //-->
            </mo-tab-box> 
          </template>
          <template #scroll>
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" >
            <ur-box-container v-show="tabIndex === 1" alignV="start" componentid="" direction="column" class="ns-check-sum">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title fexTy4">
                    <span class="fs14rem fwn mt-2" style="color: #6B7684;">* 신규는 5일간 조회가능</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- 검색결과 -->
            <ur-box-container v-if="showReturnStr && !isInit" alignV="start" direction="column" class="search-result-box bd-b-Ty1">
              <strong class="tit">검색결과</strong>
              <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
                <mo-tab-label idx="1" v-if="showOrgData" @click="fn_OpenMSPCM116P">
                  <span class="txt">{{ pOrgData135M.hofOrg.label }}</span>
                </mo-tab-label>
                <mo-tab-label idx="1" v-if="showOrgData" @click="fn_OpenMSPCM116P">
                  <span class="txt">{{ pOrgData135M.dofOrg.label }}</span>
                </mo-tab-label>
                <mo-tab-label idx="1" v-if="showOrgData" @click="fn_OpenMSPCM116P">
                  <span class="txt">{{ pOrgData135M.fofOrg.label }}</span>
                </mo-tab-label>
                <mo-tab-label idx="1" v-if="showOrgData" @click="fn_OpenMSPCM116P">
                  <span class="tit">컨설턴트</span>
                  <span class="txt">{{ pOrgData135M.cnslt.label }}</span>
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[0]" @click="fn_OpenMSPCM116P">
                  <span class="tit">조회시작일</span>
                  <span class="txt">{{ lv_ReturnStr[0] }}</span>
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[1]" @click="fn_OpenMSPCM116P">
                  <span class="tit">조회종료일</span>
                  <span class="txt">{{ lv_ReturnStr[1] }}</span>
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[2]" @click="fn_OpenMSPCM116P">
                  <span class="tit">고객명</span>
                  <span class="txt">{{ lv_ReturnStr[2] }}</span>
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[3]" @click="fn_OpenMSPCM116P">
                  <span class="tit">휴대폰번호</span>
                  <span class="txt">{{ lv_ReturnStr[3] }}</span>
                </mo-tab-label>
              </mo-tab-box>
            </ur-box-container>
            <!-- Content영역 -->
            <div v-if="!isEmptyView" class="full">
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-list2 pcls-list pb50">
                <mo-list :list-data="cmelstPrgStatDVO">
                  <template #list-item="{ item }">
                    <mo-list-item>
                      <div class="list-item__contents">
                        <!-- 고객명, 동의상황 -->
                        <div class="list-item__contents__title fexTy3">
                          <span v-show="tabIndex === 1" class="name txtSkip">{{ item.mobslChnlCustNm }}</span>
                          <span v-show="tabIndex === 2" class="name txtSkip" @click.stop="fn_OpenCustInfoBs(item)">{{ item.mobslChnlCustNm }}</span>
                          <mo-badge v-if="item.mobpsPrgPhseCd === '05'" class="badge-sample-badge green sm" text="동의완료" shape="status">동의완료</mo-badge>
                        </div>
                        <!-- 생년월일 -->
                        <div v-show="tabIndex === 2" class="list-item__contents__info">
                          <span>{{ item.dob.trim() ? $commonUtil.dateDashFormat($bizUtil.getDotDateToFullDate(item.dob)) : '-' }}</span>
                        </div>
                        <div v-if="hasAuth" class="list-item__contents__info">
                          <span class="crTy-bk7 fs14rem mr10">발송사번</span><span>{{ item.ernam }}</span>
                        </div>
                        <div class="list-item__contents__info">
                          <span class="crTy-bk7 fs14rem mr10">발송일시</span><span>{{ item.dspchDtm }}</span>
                        </div>
                        <div class="list-item__contents__info">
                          <mo-button color="primary" shape="border" size="medium" class="ns-btn-round" @click.stop="fn_OpenDetail(item)">상세조회</mo-button>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>
            </div>

            <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
          </ur-box-container>
          </template> 
        </mo-collapsing-header-view> 
      </div>
    </ur-box-container>

    <mo-bottom-sheet ref="bottomSheet" class="ns-bottom-sheet" :close-btn="true">
        <template v-slot:title>
          상세조회
          <div class="ns-btn-close" @click="$refs.bottomSheet.close" name="닫기"></div>
        </template>
        <ul class="msp-pls-guide pb0">
          <li class="msp-pls-txt fs16rem">
            <span class="crTy-bk7 fs14rem mr10">고객명</span><span>{{selectedCustInfo.mobslChnlCustNm}}</span>
          </li>
          <li v-show="tabIndex === 2" class="msp-pls-txt fs16rem">
            <span class="crTy-bk7 fs14rem mr10">생년월일</span><span>{{ selectedCustInfo.dob && selectedCustInfo.dob.trim() ? $commonUtil.dateDashFormat($bizUtil.getDotDateToFullDate(selectedCustInfo.dob)) : '-' }}</span>
          </li>
          <li v-show="hasAuth" class="msp-pls-txt fs16rem">
            <span class="crTy-bk7 fs14rem mr10">발송사번</span><span>{{ selectedCustInfo.ernam }}</span>
          </li>
          <li class="msp-pls-txt fs16rem">
            <span class="crTy-bk7 fs14rem">발송번호</span><span>{{ selectedCustInfo.contPtcpTelnoEncr && selectedCustInfo.contPtcpTelnoEncr.trim() ? $commonUtil.fullPhoneDashFormat(selectedCustInfo.contPtcpTelnoEncr) : '-' }}</span>
          </li>
          <li class="msp-pls-txt fs16rem">
            <span class="crTy-bk7 fs14rem mr10">발송일시</span><span>{{ selectedCustInfo.dspchDtm }}</span>
          </li>
          <li class="msp-pls-txt fs16rem">
            <span class="crTy-bk7 fs14rem">전송항목</span>
            <span v-if="selectedCustInfo.elstDtCntnt === '17'">필수컨설팅동의</span>
            <span v-if="selectedCustInfo.elstDtCntnt === '19'">마케팅동의</span>
            <span v-if="selectedCustInfo.elstDtCntnt === '18'"><span>필수컨설팅동의</span><em>|</em><span> 마케팅동의</span></span>
          </li>
          <li class="msp-pls-txt fs16rem">
            <span class="crTy-bk7 fs14rem">요청일시</span><span>{{ $commonUtil.dateDashFormat(selectedCustInfo.cnsntReqYmd) }}</span>
          </li>
          <li class="msp-pls-txt fs16rem">
            <span class="crTy-bk7 fs14rem">요청방법</span><span>{{ selectedCustInfo.ltrqMthod }}</span>
          </li>
        </ul>
      <template v-slot:action>
        <ur-box-container alignV="start" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="$refs.bottomSheet.close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPCM116P from '@/ui/cm/MSPCM116P' // 전체고객 목록 검색

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM135M",
  screenId: "MSPCM135M",
  components: {
    MSPCM116P
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if (this.$cmUtil.isSrchOrgCombo() === "Y") {
      this.isMngr = true;
    }

    // post 호출 전 Progress 타입 설정
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

    // 리스트 조회 서비스 호출
    this.fn_GetService()
  },

  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    this.$nextTick(() => {
      this.scrollElementId = this.$refs.collapsingHeaderView.getScrollElementId();
    })
  },

  computed: {
    showOrgData() {
      return this.isMngr && !_.isEmpty(this.pOrgData135M);
    },
    showReturnStr() {
      if (this.showOrgData) {
        return true;
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true;
        }
      }
      return false;
    },
    hasAuth() {
      return this.searchDtlFcList.includes(this.lv_cnsltNo);
    }
  },

  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      scrollElementId: '',
      tabIndex: 1,  // 현재 탭
      
      // 제어 변수
      isLoading: false,
      isCustCardExist: false, // 채널고객ID 여부
      isEmptyView: false,
      isFinished: false,  // 페이징 끝

      // 데이터 변수
      userInfo: {},
      lv_cnsltNo: '',
      cmelstPrgStatDVO: [],
      pagesize: 20,
      lv_nextVuchId: '99999999999999',
      today: moment(new Date()),
      
      popup116: {}, // MSPCM116P 목록 검색
      pOrgData135M: {}, // 최근 조직 조회 데이터
      searchKeyword: '', // 고객명
      telNo: '', // 휴대전화번호
      startDate: '', // 조회시작일
      endDate: '', // 조회종료일
      isInit: false, // 검색 초기화 여부
      isSearched: false, // 조회이력 저장 변수
      lv_ReturnStr: [], // 상세조회조건 텍스트 배열
      isMngr: false, // 지점장 권한 여부
      searchDtlFcList: [], // 상세조회 가능 FC 목록
      selectedCustInfo: {} // 선택한 고객 정보
    };
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.isSearched) {
        // 조회변수 초기화
        this.fn_InitValue();
        // 재조회
        this.fn_GetService();
      } else {
        this.$router.go(-1);
      }
    },
    
    /*********************************************************
     * @function fn_InitValue
     * @notice   주요 변수 초기화
     *********************************************************/
    fn_InitValue() {
      this.pOrgData135M = {}; // 조직 조회 데이터
      this.cmelstPrgStatDVO = [];
      this.lv_nextVuchId = '99999999999999'
      this.searchKeyword = '' // 고객명
      this.telNo = ""; // 휴대전화번호
      this.startDate = ""; // 조회시작일
      this.endDate = ""; // 조회종료일

      this.isSearched = false; // 검색이력 초기화
      this.isInit = true; // 검색초기화 여부
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 비대면동의 발송이력 서비스 호출
     ******************************************************************************/
    fn_GetService () {
      let lv_Vm = this
      // const trnstId = 'txTSSCM90S3'
      const trnstId = 'txTSSCM48S7'
      const auth = 'S'
      let pParams = {
        ernam: lv_Vm.lv_cnsltNo,
        pageSize: lv_Vm.pagesize,
        nextVuchId : lv_Vm.lv_nextVuchId,
        mobslChnlCustNm: lv_Vm.searchKeyword,
        contPtcpTelnoEncr: lv_Vm.telNo,
        erStrdat: lv_Vm.startDate,
        erEnddat: lv_Vm.endDate,
        newYn: lv_Vm.tabIndex === 1 ? 'Y' : 'N'
      }

      // 지점장 조회시 구분
      if (this.pOrgData135M.cnslt !== undefined && this.isMngr === true) {
        pParams.ernam = this.pOrgData135M.cnslt.key; // 선택한 컨설턴트 번호
      }

      // 휴대폰번호 검색 시 컨설턴트 선택 안함
      if (this.telNo) {
        pParams.ernam = '';
      }

      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pParams.ernam = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            lv_Vm.searchDtlFcList = response.body.searchDtlFcList
            lv_Vm.lv_nextVuchId = response.body.nextVuchId
            let tmpList = response.body.cmelstPrgStatDVO
            
            tmpList.forEach(item => {
              // 생년월일
              item.dob = item.contPtcpRrnEncr.substr(0, 6)
              // 발송일시
              item.dspchDtm = moment(item.mobpsUrlDspchTrtDtm, 'YYYYMMDDhhmmss').format('YYYY-MM-DD kk:mm')

              lv_Vm.cmelstPrgStatDVO.push(item)
            })
            
            lv_Vm.isFinished = !lv_Vm.lv_nextVuchId
            lv_Vm.isEmptyView = !lv_Vm.cmelstPrgStatDVO.length
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isLoading = false
        })
    },

    /******************************************************************************
     * Function명 : fn_LoadingData
     * 설명       : infinite 로딩
     ******************************************************************************/
    fn_LoadingData () {
      if (!this.isLoading && !this.isFinished) {
        // post 호출 전 Progress 타입 설정
        window.vue.getStore('progress').dispatch('PART')
        
        this.fn_GetService()
      }
    },

    /******************************************************************************
     * Function명: fn_OpenMSPCM116P
     * 설명: 고객명 검색 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM116P () {
      let lv_Vm = this

      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: 'MSPCM135M',
          pOrgData: this.pOrgData135M, // 최근 조직 조회 데이터
          pSearchKeyword: this.searchKeyword, // 고객명
          pTelNo: this.telNo, // 휴대폰번호
          pStartDate: this.startDate, // 조회시작일
          pEndDate: this.endDate, // 조회종료일
          pIsMngr: this.isMngr
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            lv_Vm.$bottomModal.close(lv_Vm.popup116)

            lv_Vm.lv_nextVuchId = '99999999999999'
            lv_Vm.searchKeyword = pData.rtnData.searchKeyword
            lv_Vm.telNo = pData.rtnData.telNo
            lv_Vm.startDate = pData.rtnData.startDate
            lv_Vm.endDate = pData.rtnData.endDate

            // 마지막 조직 조회 데이터 셋팅
            lv_Vm.pOrgData135M = pData.rtnData.pOrgData

            // 조회이력 저장
            lv_Vm.isSearched = true
            // 검색 초기화 여부
            lv_Vm.isInit = pData.isInit
            if (lv_Vm.isInit) lv_Vm.isSearched = false
            // 조회조건 텍스트 세팅
            lv_Vm.lv_ReturnStr = pData.srchOpt
            
            lv_Vm.cmelstPrgStatDVO = []

            // 조회
            lv_Vm.fn_GetService()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = "";
      let nmUiObject = {}

      if (this.pOrgData135M.cnslt === undefined || this.pOrgData135M.cnslt === null || this.pOrgData135M.cnslt === "") {
        cnsltNo = this.userInfo.userId;
      } else {
        cnsltNo = this.pOrgData135M.cnslt.key;
      }

      nmUiObject = {
        chnlCustId : item.mobslChnlCustId, //고객채널 ID
        parentId   : lv_Vm.$options.screenId, //화면ID+
        // cnsltNo : this.cnsltInfo.userId,
        cnsltNo : cnsltNo,
        custNm : item.mobslChnlCustNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenDetail
     * 설명       : 발송번호 팝업
     ******************************************************************************/
    fn_OpenDetail(item) {
      this.selectedCustInfo = item;
      this.$refs.bottomSheet.open();
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              this.$router.go(-1)
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_ChangeTab
     * 설명       : 탭 변경
     ******************************************************************************/
    fn_ChangeTab(index) {
      this.tabIndex = index
      this.cmelstPrgStatDVO = []; // 목록 초기화
      this.lv_nextVuchId = '99999999999999' // 페이징 초기화
      this.fn_GetService();
    }
  }
};
</script>
<style scoped>
</style>